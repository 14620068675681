import "./App.css";
import "./fonts/Nunito-VariableFont_wght.ttf";
import "./fonts/Nunito-Italic-VariableFont_wght.ttf";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Header from "./components/header";
import {ThemeProvider} from "@emotion/react";
import Footer from "./components/footer";
import {theme} from "./components/theme";
import {Paper} from "@mui/material";
import Box from "@mui/material/Box";

function App() {

  const welcomeMessage = "App Design and Development";

  return (
      <div>
        <ThemeProvider theme={theme}>
          <Header welcome={welcomeMessage} />
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 0,
                        width: '100%',
                        height: '100%',
                        textAlign: 'left',
                        alignItems: 'center'
                    },
                }}
            >
                {/*<Paper elevation={4} sx={{ backgroundColor: '#ffffff', p: 2, minHeight: '420px' }}>*/}
                  <Routes>
                    {/*<Route path='/home/:bookNow' element={<Home/>} />*/}
                    <Route path='/home' element={<Home/>} />
                    <Route path='/about' element={<About/>} />
                    <Route path='/contact' element={<Contact join={0} key={Math.random()} />} />
                    {/*<Route path='/join' element={<Contact join={1} key={Math.random()} />} />*/}
                    <Route path='/services' element={<Services/>} />
                    {/*<Route path='/team' element={<Team/>} />*/}
                    {/*<Route path='/account' element={<Account*/}
                    {/*    validatedFormat={validatedFormat}*/}
                    />} />
                    {/*<Route path='/book-now' element={<BookNow*/}
                    {/*    validatedFormat={validatedFormat}*/}
                    {/*/>} />*/}
                    {/*<Route path='/cart' element={<Cart/>} />*/}
                    {/*<Route path='/profile' element={<Profile/>} />*/}
                    {/*<Route path='/welcome' element={<Welcome/>} />*/}
                    {/*<Route path='/thank-you' element={<ThankYou/>} />*/}
                    {/*<Route path='/goodbye' element={<Goodbye/>} />*/}
                    {/*<Route path='/complete' element={<Complete/>} />*/}
                    {/*<Route path='/logout' element={<LogOut/>} />*/}
                    <Route path='/*' element={<Home/>} />

                  </Routes>
                {/*</Paper>*/}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    '& > :not(style)': {
                        m: 1,
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        alignItems: 'center'
                    },
                }}
            >
                {/*<Paper elevation={4} sx={{ backgroundColor: '#ffffff', width: '90%' }}>*/}
                    <Footer />
                {/*</Paper>*/}
            </Box>
        </ThemeProvider>
      </div>
  );
}

ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById("root")
);

export default App;
