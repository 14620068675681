import Typography from "@mui/material/Typography";
import * as React from "react";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function contact() {
    return (
        <>
            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#000000' }}>
                <Grid sm={1} item sx={{ pb:1 }}>
                    <img src="./telephone1.png" width="300px" alt={'telephone'}/>
                </Grid>
                <Grid sm={3} item sx={{color: '#ffffff'}}>

                    <Typography variant='h1'>
                        Contact
                    </Typography>

                    <Typography variant='h2' sx={{ pb:2 }}>
                        You can reach us by phone...
                    </Typography>

                    <Box sx={{ pb:2 }}>
                        <Typography variant='p'>
                            <Button variant='outlined' target='_blank' href={'tel:6146363244'}>call us from your mobile</Button>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#e5e5e5' }}>
                <Grid sm={1} item sx={{ pt:1 }}>
                    <img src="./email2.png" width="300px" alt={'email'}/>
                </Grid>
                <Grid sm={3} item sx={{color: '#000000'}}>

                    <Typography variant='h2' sx={{ pb:2 }}>
                        Or send us an electronic message:
                    </Typography>

                    <Box sx={{ pb:2 }}>
                        <Typography variant='p'>
                            <Button variant='contained' target='_blank' href={'mailto:support@eekotech.com?subject=Website Email (EEKOTECH)'}>Email us from your mobile</Button>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}
