import * as React from 'react';
import {Grid, Typography} from "@mui/material";
import * as global from "./custom";
import MenuButtonOnWhiteBg from "./button/menuButtonOnWhiteBg";
import {Link} from "react-router-dom";

function Footer() {

    // const footerIconSize = global.custom.icon.footer;
    const footerText = global.custom.text.footer;
    const footerColor = global.custom.color.footer;
    const footerButton = global.custom.button.footer;
    const pages = global.custom.pages;

    return (
        <>

            {/*Footer Menu Buttons*/}
            <Grid container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                      display: { xxs: 'flex', md: 'flex' },
                      width: '100%'
                  }}
            >
                <Grid item paddingLeft={1}>
                    <Typography
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {pages.map((page) => (

                            <MenuButtonOnWhiteBg
                                key={page}
                                sx={{ my: 2, display: { xxs: 'flex', md: 'flex', marginLeft: 1, marginRight: 1 }, fontSize: footerButton, color: '#000000' }}
                                component={Link}
                                to={'/' && page}
                            >
                                {page}
                            </MenuButtonOnWhiteBg>

                        ))}
                    </Typography>

                </Grid>
                <Grid item>
                    <Typography sx={{ fontSize: footerText, color: footerColor, pb: 2 }} >
                        © 2022 Copyright - EEKOTECH LLC. All rights reserved.
                    </Typography>
                </Grid>
            </Grid>

        </>


    )
};

export default Footer;
