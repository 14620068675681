export const custom = {
    icon: {
        main: 24,
        footer: 14,
    },
    pages: ['home', 'about', 'services', 'contact'],
    services: ['Web App Design & Development','AI Application Development','Application Monitoring'],
    text: {
        footer: 15,
    },
    color: {
        footer: '#000',
        about: '#000',
        contact: '#000',
        drawerIcon: '#fff'
    },
    background: {
        contact: '#fff'
    },
    button: {
        footer: 12
    },
    address: {
        number: "7048",
        street: "Maynard Pl",
        city: "New Albany",
        state: "OH",
        zip: "43054"
    },
    phone: {
        areaCode: "614",
        number: "392-7993"
    }
}
