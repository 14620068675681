import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { keyframes } from "@emotion/react";

const enterKeyframe = keyframes`
  0% {
    transform: scale(0);
    opacity: .8;
  }
  100% {
    transform: scale(1);
    opacity: .8;
  }
`;
const MenuButtonOnWhiteBg = styled(Button)`
  text-decoration-color: transparent;
  text-decoration-thickness: 4px;
  text-transform: lowercase;
  font-size: 24px;
  color: black
  letter-spacing: 0px;
  padding: 2px;
  line-height: 2.0;
  && .MuiTouchRipple-child {
    background-image: linear-gradient(to right, #000000, #ffffff);
  }
  && .MuiTouchRipple-rippleVisible {
    opacity: 1;
    animation-name: ${enterKeyframe};
    animation-duration: 550ms;
    animation-timing-function: ${({ theme }) =>
    theme.transitions.easing.easeInOut};
  }
`;
export default MenuButtonOnWhiteBg;
