import Typography from "@mui/material/Typography";
import * as React from "react";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";

export default function about() {
    return (
        <>
            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#000000' }}>
                <Grid item>
                    <img src="./friends5.png" alt='' width='300px' display='flex'/>
                </Grid>
                <Grid item sx={{color: '#ffffff'}} xs={6}>

                    <Typography variant='h1'>
                        About
                    </Typography>

                    <Typography variant='h2' sx={{ pt:2 }}>
                        We Are Passionate About Design and Software Engineering
                    </Typography>

                    <Box sx={{ pt:2 }}>
                        <Typography variant='p'>
                            We are a small application design and development firm that specializes in usability and engineering.
                        </Typography>
                    </Box>
                    <Box sx={{ pt:2 }}>
                        <Typography variant='p'>
                            Our mission is to enable you to achieve your business goals through the use of modern technology.
                        </Typography>
                    </Box>
                    <Box sx={{ pt:2 }}>
                        <Typography variant='p'>
                            We achieve our mission by becoming an advocate for your vision and end goal.
                        </Typography>
                    </Box>

                </Grid>
            </Grid>

        </>
    )
}
