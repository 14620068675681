import Typography from "@mui/material/Typography";
import * as React from "react";
import {Grid} from "@mui/material";

export default function home() {
    return (
        <>
            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#000000' }}>
                <Grid item>
                    <img src='./futuristic-design1.png' width='300px' alt='' />
                </Grid>
                <Grid item sx={{p: 2, color: '#ffffff'}}>
                    <Typography variant='h1'>
                        Welcome to EEKOTECH!
                    </Typography>
                    <Typography variant='h2'>
                        Innovation in Application Development
                    </Typography>
                    <Typography variant='p'>
                        We specialize in the development of online web applications.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#e5e5e5' }}>
                <Grid item>
                    <Typography variant='h1'>
                        What we do:
                    </Typography>
                    <Typography variant='h2'>
                        We develop web applications across many different domains.
                    </Typography>
                    <Typography variant='p'>
                        Our intent is to innovate with modern technology stacks to deliver on business goals.
                    </Typography>
                </Grid>
                <Grid item sx={{p: 2, color: '#ffffff'}}>
                    <img src='./typing-fast2.png'  width='300px' alt='' />
                </Grid>
            </Grid>

            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#000000' }}>
                <Grid item>
                    <img src='./beautiful-cyborg1.png'  width='300px' alt='' />
                </Grid>
                <Grid item sx={{p: 2, color: '#ffffff'}}>
                    <Typography variant='h1' sx={{color: '#ffffff'}}>
                        How do we get started:
                    </Typography>
                    <Grid item>
                        <Typography variant='h2'>
                            We work with you to understand your vision and end goal.
                        </Typography>
                        <Typography variant='p'>
                            We create a plan and a design to meet your business needs.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#e5e5e5' }}>
                <Grid item sx={{p: 2, color: '#000000'}}>
                    <Typography variant='h1' sx={{p: 2, color: '#000000'}}>
                        What we deliver:
                    </Typography>
                    <Typography variant='h2'>
                        <ul>
                            <li>
                                A professional design
                            </li>
                            <li>
                                A web application that matches your vision and design
                            </li>
                            <li>
                                Analytics to track your application's performance
                            </li>
                            <li>
                                Deployment Tools to easily make changes to your application
                            </li>
                            <li>
                                Custom code that you own and control
                            </li>
                        </ul>
                    </Typography>

                </Grid>
                <Grid item>
                    <img src='./designer-art1.png'  width='300px' alt='' />
                </Grid>
            </Grid>

        </>
    )
}
