import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";

export default function about() {
    return (
        <>

            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#000000' }}>
                <Grid item sm={1} sx={{ pb:1 }}>
                    <img src="./moon-gaze1.png" width="300px" alt={'moon-gaze-about-your-application'}/>
                </Grid>
                <Grid item sm={6} sx={{color: '#ffffff'}}>

                    <Typography variant='h1'>
                        Services
                    </Typography>

                    <Typography variant='h2' sx={{ pb:2 }}>
                        Web Application Design and Fullstack Development
                    </Typography>

                    <Box sx={{ pb:2 }}>
                        <Typography variant='p'>
                            Your application should be created based on how you visualize it. We work closely with you to achieve this.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant='p' sx={{ pb:2 }}>
                            Once your vision is in place, we will create a development plan and a user interface design to meet your business needs.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>


            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#e5e5e5' }}>
                <Grid item sm={1} sx={{ pt:1 }}>
                    <img src="./color-choosing1.png" alt='' width='300px'/>
                </Grid>
                <Grid item sm={6} sx={{color: '#000000'}}>

                    <Typography variant='h1'>
                        Design Details
                    </Typography>

                    <Typography variant='h2' sx={{ pb:2 }}>
                        Keeping Your Vision in Mind
                    </Typography>
                    <Box sx={{ pb:2 }}>
                        <Typography variant='p'>
                            We start with the basics and move up to the more complex details as we iterate through your design
                            until we have the application designed with the goal of achieving your vision.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container display='flex' justifyContent='space-around' alignItems='center' sx={{ p:2, backgroundColor: '#000000' }}>
                <Grid item sm={1} sx={{ pb:1 }}>
                    <img src="./calendar-black-bg1.png" alt='' width='300px'/>
                </Grid>
                <Grid item sm={6} sx={{color: '#ffffff'}}>

                    <Typography variant='h1'>
                        Getting Started With Your Project
                    </Typography>

                    <Box>
                        <Typography variant='h2' sx={{ pb:2 }}>
                            Establish a Timeline
                        </Typography>
                        <Typography variant='p'>
                            Once we have a clear goal and plan established, we will work with you to establish a timeline for the project. Coding will begin and
                            we will send you updates as we progress and are ready to deploy the application into a live environment.
                        <br /><br />
                            <a href={'/contact'} style={{ color: '#ffffff' }}>Contact us</a> to get started today!
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}
